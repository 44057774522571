import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import AuthAPI from "../../services/API/clients/AuthAPI";
import { toast } from "react-toastify";
import { Input } from "@material-tailwind/react";
import HandleErrors from "../../components/HandleErrors";
import { Storage } from "../../services/storage";

export default function Login() {
    const [loading, setLoading] = React.useState(false);
    
    const [status, setStatus] = React.useState<{ message: string[] }>({
        message: [],
    });
    const [formData, setFormData] = React.useState({
        email: "",
        password: "",
    });

    const formInput = [
        {
            name: "email",
            label: "Adresse Mail",
        },
        {
            name: "password",
            label: "Mot de passe",
        },
    ];

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setLoading(true);
        const datas = {
            email: formData.email,
            password: formData.password,
        };
        const response = await AuthAPI.login(datas);
        const { body } = response;
        if (response.status === 200) {
            await toast.success("Vous êtes connecté !");
            Storage.setToken(body.access_token);
            Storage.setRefreshToken(body.refresh_token);
            Storage.setRoles(body.roles);
            const respMe = await AuthAPI.me();
            if (respMe.status === 200) {
                Storage.setAccountId(respMe.body.accountId);
                Storage.setPracticerId(respMe.body.practicerId);
                Storage.setCompanyId(respMe.body.companyId);
                Storage.setContactId(respMe.body.contactId);
                Storage.setTutorialId(respMe.body.tutorialId);
                window.location.href = "/dash/order";
            }
            setLoading(false);
        } else if (response.status === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
            setStatus({ message: body.error.response.message });
            setLoading(false);
        } else {
            toast.error("Le mot de passe ou l'adresse mail est incorrect");
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden">
            <div className="relative py-3 mx-auto w-full md:w-1/2">
                <div className="absolute inset-0 bg-gradient-to-r from-capucine-700 to-capucine-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-auto">
                    <h1 className="text-2xl text-royal-900 font-semibold">Espace Client</h1>
                    <form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
                        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                            {formInput.map((input) => (
                                <div className="relative" key={input.name}>
                                    <Input
                                        autoComplete="off"
                                        id={input.name}
                                        name={input.name}
                                        type={input.name}
                                        variant="standard"
                                        color="deep-purple"
                                        label={input.label}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {input.name === "email" && status.message.includes("email must be an email") && (
                                        <HandleErrors error="L'adresse mail doit être une adresse mail valide" color="red" />
                                    )}
                                </div>
                            ))}
                            <div className="relative">
                                <div className="flex justify-end w-full">
                                <a href="/ask-reset" className="text-right text-sm w-fit">
                                    <b>Mot de passe oublié ?</b>
                                </a>
                                </div>

                                <button className="bg-royal-900 hover:bg-royal-800 text-white rounded-md px-2 py-1">
                                    {loading && <FontAwesomeIcon icon="spinner" className="animate-spin" />} Se connecter
                                </button>

                                <a href="/register" className="block text-left mt-2 text-sm">
                                    Vous n'avez pas de compte ? <b>Inscrivez-vous</b>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
