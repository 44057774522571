import { Storage } from "../../storage";
import APIClient from "../APIClients";

const roles = Storage.getRoles()

export default class AuthAPI {
    public static async login(data: {}) {
        return APIClient.noInterceptor.post("/auth/login", data);
    }

    public static async register(data: {}) {
        return APIClient.noInterceptor.post("/company", data);
    }

    public static async me() {
        return APIClient.client.get("/account/me");
    }

    public static async autoLogin(token: string) {
        return APIClient.client.post(`/auth/auto-login/${token}`, {});
    }

    public static async updatePassword(data: {}, query: string) {
        return APIClient.client.patch(`/account/${query}`, data)
    }

    public static async askReset(data: {}) {
        return APIClient.noInterceptor.post(`/auth/reset-password`, data)
    }

    public static async resetPassword(id: string, data: {}) {
        return APIClient.noInterceptor.put(`/auth/reset-password/${id}`, data)
    }
}
