import { Dialog } from "@headlessui/react";
import { Alert, Card, IconButton, Input, Textarea, Typography } from "@material-tailwind/react";
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import Verification from "../../services/API/clients/OrderAPI";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import PromptPopup from "../GroupPrompt";
import JawTrackerDropDown from "../JawTrackerDropDown";
import PictureDropDown from "../PictureDropDown";

export default function VerifClient({
    setOpen,
    data,
    handleStatus,
}: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any; handleStatus: any }>) {
    const cancelButtonRef = useRef(null);
    const [order, setOrder] = React.useState<any>({});
    const [files, setFiles] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [openFilesParts, setOpenFilesParts] = React.useState<any>({});

    const fetchFiles = useCallback(async () => {
        const response = await Verification.getFiles(data.id);
        if (response.status === 200) {
            setFiles(response.body);
            console.log(response.body);
        }
    }, [data]);

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    useEffect(() => {
        setOrder(data);
    }, [data]);

    const endTaskTrue = async () => {
        setLoading(true);
        confirmAlert({
            title: "Confirmation",
            message: "Êtes-vous sûr de vouloir valider la vérification ? Cette action est irréversible.",
            buttons: [
                {
                    label: "Oui",
                    onClick: async () => {
                        const datas = {
                            targetId: data.id,
                            result: true,
                        };
                        const response = await Verification.finishVerification(datas);
                        if (response.status === 200 || response.status === 201) {
                            handleStatus("success", "La vérification a bien été acceptée");
                            setOpen("working", true);
                        } else if (response.status === 409) {
                            handleStatus("already", "La fin de vérification a déjà été effectuée");
                        } else if (response.status === 400) {
                            handleStatus("complete", "Veuillez vérifier que tous les champs ont bien été complété");
                        } else {
                            handleStatus("error", "Une erreur est survenue lors de la modification de la commande");
                        }
                    },
                },
                {
                    label: "Non",
                    onClick: () => {},
                },
            ],
        });
        setLoading(false);
    };

    const endTaskFalse = async (comment: string, success: boolean) => {
        if (isLoading) return;
        setIsLoading(true);
        if (success) {
            const datas = {
                targetId: data.id,
                result: false,
                comment: comment,
            };
            const response = await Verification.finishVerification(datas);
            if (response.status === 200 || response.status === 201) {
                handleStatus("success", "La vérification a bien été refusée");
                setOpen("working", true);
                setIsLoading(false);
            } else if (response.status === 409) {
                handleStatus("already", "La fin de vérification a déjà été effectuée");
                setIsLoading(false);
            } else if (response.status === 400) {
                handleStatus("complete", "Veuillez vérifier que tous les champs ont bien été complété");
                setIsLoading(false);
            } else {
                handleStatus("error", "Une erreur est survenue lors de la modification de la commande");
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const getMyFile = async (id: string, name: string) => {
        await Verification.downloadFile(id, name);
    };

    const downloadAllOfThem = (key: string) => {
        files[key].files.forEach((fichier: { id: string; name: string }) => {
            getMyFile(fichier.id, fichier.name);
        });
    };

    const handleChangeFilesOpening = (index: number) => {
        setOpenFilesParts((prev: any) => {
            return { ...prev, [index]: !prev[index] };
        });
    };

    return (
        <>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
                <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-left sm:ml-4 sm:mt-0 w-full">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 border-b pb-2">
                            Modification de {data?.code}
                        </Dialog.Title>
                        <div className="flex flex-col md:flex-row gap-4 justify-center">
                            <div className="flex flex-col items-start content-start justify-start mt-2 gap-2 w-full md:w-1/2">
                                <div className="w-full flex flex-row items-center justify-between border-b border-b-lime-500">
                                    <Typography color="blue-gray" className="font-normal text-left py-2 pl-2 w-full">
                                        Informations générales
                                    </Typography>
                                </div>

                                <Alert color="deep-purple">
                                    <b>Echéance : </b> {moment(data.deliveryTarget).format("DD.MM.YYYY")}
                                </Alert>

                                <Typography color="blue-gray" variant="small" className="font-normal text-left">
                                    Praticien
                                </Typography>
                                <Input label="Patient" type="text" color="blue-gray" value={data.practicerFullName} className="w-96" disabled />
                                <Typography color="blue-gray" variant="small" className="font-normal text-left">
                                    Patient
                                </Typography>
                                <Input label="Client" type="text" color="blue-gray" value={data.patientFullName} className="w-96" disabled />
                                <Typography color="blue-gray" variant="small" className="font-normal text-left">
                                    Compagnie
                                </Typography>
                                <Input label="Laboratoire" type="text" color="blue-gray" value={data.companyName} className="w-96" disabled />
                                <Typography color="blue-gray" variant="small" className="font-normal text-left">
                                    Date de création
                                </Typography>
                                <Input
                                    label="Date de création"
                                    type="text"
                                    color="blue-gray"
                                    value={moment(data.createdAt).format("DD/MM/YYYY")}
                                    className="w-96"
                                    disabled
                                />

                                <Typography color="blue-gray" variant="small" className="font-normal text-left">
                                    Commentaire
                                </Typography>
                                <Textarea label="Commentaire" color="blue-gray" value={data.comment} className="w-96" disabled />

                                <div className="w-full flex flex-row items-center justify-between border-b border-b-lime-500">
                                    <Typography color="blue-gray" className="font-normal text-left py-2 pl-2 w-full">
                                        Ajouter des fichiers
                                    </Typography>
                                </div>
                                <div className="flex justify-between w-full gap-6">
                                    <PictureDropDown fetchFiles={fetchFiles} id="files-client" orderId={data.id} />
                                    <JawTrackerDropDown data={data} fetchJawTracker={fetchFiles} id="jawTracker" />
                                </div>

                                <div className="w-full flex flex-row items-center justify-between border-b border-b-lime-500">
                                    <Typography color="blue-gray" className="font-normal text-left py-2 pl-2 w-full">
                                        Fichiers
                                    </Typography>
                                </div>

                                <div className="flex flex-row items-center justify-between w-full">
                                    <Typography color="blue-gray" className="font-normal text-left pt-2 pl-2">
                                        {files.uploaded !== undefined && files.uploaded.name}
                                    </Typography>
                                    <div className="flex flex-row items-center justify-center gap-2">
                                        <IconButton
                                            size="sm"
                                            disabled={files.uploaded !== undefined && files.uploaded.files.length === 0}
                                            onClick={() => downloadAllOfThem("uploaded")}
                                            color="lime"
                                        >
                                            <FontAwesomeIcon icon="download" className="text-white" />
                                        </IconButton>
                                        <IconButton
                                            size="sm"
                                            disabled={files.uploaded !== undefined && files.uploaded.files.length === 0}
                                            onClick={() => handleChangeFilesOpening(0)}
                                            color="deep-purple"
                                        >
                                            <FontAwesomeIcon icon={`${openFilesParts[0] ? "chevron-up" : "chevron-down"}`} />
                                        </IconButton>
                                    </div>
                                </div>
                                {openFilesParts[0] && (
                                    <div className="p-2 bg-gray-50 w-full">
                                        {files.uploaded !== undefined && files.uploaded.files.length === 0 ? (
                                            <Typography variant="small" className="font-normal text-blue-gray-700 text-sm">
                                                Aucun fichier
                                            </Typography>
                                        ) : (
                                            files.uploaded !== undefined && files.uploaded.files.map((fichier: { id: string; name: any }) => (
                                                <Typography
                                                    key={fichier.id}
                                                    variant="small"
                                                    className="font-normal text-blue-gray-700 text-sm flex items-center"
                                                >
                                                    <FontAwesomeIcon
                                                        icon="download"
                                                        onClick={() => getMyFile(fichier.id, fichier.name)}
                                                        className="bg-royal-900 hover:bg-royal-800 p-2 text-xs text-white rounded-lg mr-2 mb-0.5 cursor-pointer"
                                                    />
                                                    {fichier.name}
                                                </Typography>
                                            ))
                                        )}
                                    </div>
                                )}
                                <div className="flex flex-row items-center justify-between w-full">
                                    <Typography color="blue-gray" className="font-normal text-left pt-2 pl-2">
                                        {files.delivery !== undefined && files.delivery.name}
                                    </Typography>
                                    <div className="flex flex-row items-center justify-center gap-2">
                                        <IconButton
                                            size="sm"
                                            disabled={files.delivery !== undefined && files.delivery.files.length === 0}
                                            onClick={() => downloadAllOfThem("delivery")}
                                            color="lime"
                                        >
                                            <FontAwesomeIcon icon="download" className="text-white" />
                                        </IconButton>
                                        <IconButton
                                            size="sm"
                                            disabled={files.delivery !== undefined && files.delivery.files.length === 0}
                                            onClick={() => handleChangeFilesOpening(1)}
                                            color="deep-purple"
                                        >
                                            <FontAwesomeIcon icon={`${openFilesParts[1] ? "chevron-up" : "chevron-down"}`} />
                                        </IconButton>
                                    </div>
                                </div>
                                {openFilesParts[1] && (
                                    <div className="p-2 bg-gray-50 w-full">
                                        {files.delivery !== undefined && files.delivery.files.length === 0 ? (
                                            <Typography variant="small" className="font-normal text-blue-gray-700 text-sm">
                                                Aucun fichier
                                            </Typography>
                                        ) : (
                                            files.delivery !== undefined && files.delivery.files.map((fichier: { id: string; name: any }) => (
                                                <Typography
                                                    key={fichier.id}
                                                    variant="small"
                                                    className="font-normal text-blue-gray-700 text-sm flex items-center"
                                                >
                                                    <FontAwesomeIcon
                                                        icon="download"
                                                        onClick={() => getMyFile(fichier.id, fichier.name)}
                                                        className="bg-royal-900 hover:bg-royal-800 p-2 text-xs text-white rounded-lg mr-2 mb-0.5 cursor-pointer"
                                                    />
                                                    {fichier.name}
                                                </Typography>
                                            ))
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="overflow-x-auto pt-2 w-full md:w-1/2">
                                <div className="w-full flex flex-row items-center justify-between border-b border-b-lime-500 mb-2">
                                    <Typography color="blue-gray" className="font-normal text-left py-2 pl-2 w-full">
                                        Liste des produits commandés
                                    </Typography>
                                </div>
                                <Card className="overflow-scroll h-auto w-full">
                                    {data.products.length === 0 ? (
                                        <Alert color="blue-gray">Aucun produit n'a été choisi</Alert>
                                    ) : (
                                        <table className="w-full table-auto text-left">
                                            <thead>
                                                <tr>
                                                    {["Identifiant", "Dents", "Code produit", "Prix", "Description", "Quantité"].map((head) => (
                                                        <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <Typography variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                                                                {head}
                                                            </Typography>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.products.map((product: any, index: number) => {
                                                    const isLast = index === data.products.length - 1;
                                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                                    return (
                                                        <tr key={product.productRef.code}>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {data?.code}-{index}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal min-w-[100px]">
                                                                    {product.teeth.join(", ")}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                    {product.productRef.code}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                                    {product.productRef.price / 100}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                    {product.productRef.description}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography variant="small" color="blue-gray" className="font-normal whitespace-nowrap">
                                                                    {product.quantity}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )}
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {order && order.step === "CLIENT_VERIFICATION" && (
                    <>
                        <button
                            type="button"
                            onClick={() => endTaskTrue()}
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        >
                            {loading ? <FontAwesomeIcon icon="spinner" spin /> : "Fin de vérification"}
                        </button>
                        <PromptPopup
                            color="red-600"
                            colorHover="red-500"
                            text="Demande de modification"
                            recupComment={endTaskFalse}
                            title="Demande de modification"
                            buttonText="Demander à modifier"
                            isLoading={isLoading}
                        />
                    </>
                )}
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("allInOne")}
                    ref={cancelButtonRef}
                >
                    Fermer
                </button>
            </div>
        </>
    );
}
