import { Button, Card, CardBody, CardHeader, Spinner, Textarea, Typography } from "@material-tailwind/react";
import React, { useCallback } from "react";
import "tw-elements";

import OrderAPI from "../../services/API/clients/OrderAPI";

import { toast } from "react-toastify";
import Patient from "../../services/API/clients/PatientAPI";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import Joyride from 'react-joyride';
import BottomTutorial from "../../components/BottomTutorial";
import TutorialsAPI from "../../services/API/clients/TutorialsAPI";
import { Storage } from "../../services/storage";
import Calendar from "./Calendar/Calendar";
import { steps } from "./Steps";
import SelectPatient from "./Teeths/SelectPatient";
import JawTrackerAPI from "../../services/API/clients/JawTrackerAPI";
import { FileDropZone } from "../../components/FileDropZone";

export default function Order() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [order, setOrder] = React.useState({
        patientId: "",
        patientName: "",
        patientFirstname: "",
        patientCivility: "",
        practicerId: "",
        companyId: "",
        comment: "",
    });
    const [loading, setLoading] = React.useState(false);
    const [fileList, setFileList] = React.useState<any[]>([]);
    const [jawTrackerList, setJawTrackerList] = React.useState<File[]>([]);
    const [dueDate, setDueDate] = React.useState<Moment | null>(null);
    const [step, setStep] = React.useState(0);
    const [playTutorial, setPlayTutorial] = React.useState(false);
    const [run, setRun] = React.useState(false);
    const [radio, setRadio] = React.useState("existing");

    React.useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await TutorialsAPI.getTutorials();
            if (response.status !== 200) return;
            const tutorial = response.body;
            const orderPageVisited = tutorial.find((item: any) => item.page === "/dash/order")?.visited;
            if (orderPageVisited !== undefined) {
                if (orderPageVisited) {
                    setPlayTutorial(false);
                } else {
                    if (width > 768)
                        setPlayTutorial(true);
                    else
                        setPlayTutorial(false);
                }
            }
        }
        fetchData();
    }, []);

    const patientCreate = async (companyID: string) => {
        const data = {
            lastname: order.patientName,
            firstname: order.patientFirstname,
            civility: order.patientCivility,
            companyId: companyID,
        };
        const response = await Patient.newPatient(data);
        const { body } = response;
        if (response.status === 201) {
            toast.success("Le patient a bien été créé");
            setOrder({ ...order, patientId: body.id });
            return body.id;
        } else if (response.status === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
        } else if (response.status === 409) {
            toast.error("Ce patient existe déjà");
        } else {
            toast.error("Une erreur est survenue lors de la création du patient");
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);

        if (dueDate === undefined || dueDate === null) {
            toast.warning("Vous devez choisir une date de livraison");
            setLoading(false);
            return;
        }

        if (dueDate < moment()) {
            toast.warning("La date de livraison ne peut être antérieure à aujourd'hui");
            setLoading(false);
            return;
        }

        // if (fileList.length === 0) {
        //     toast.warning("Veuillez ajouter au moins un fichier");
        //     setLoading(false);
        //     return;
        // }

        let companyID = Storage.getCompanyId() ?? "";

        let patientId = "";

        if (radio !== "existing") {
            if (order.patientName !== "" && order.patientFirstname !== "" && order.patientCivility !== "")
                patientId = await patientCreate(companyID);
            else {
                toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
                setLoading(false);
                return;
            }
        }

        const data = {
            patientId: radio === "existing" ? order.patientId : patientId,
            practicerId: order.practicerId,
            companyId: companyID,
            comment: order.comment,
            deliveryTarget: moment(dueDate).toISOString()
        };
        const response = await OrderAPI.newOrder(data);
        if (response.status === 201) {
            if (fileList.length > 0)
                await uploadFiles(response.body.id);
            if (jawTrackerList.length > 0)
                await uploadJawTracker(response.body.id, order.patientId !== "" ? order.patientId : patientId);
            if (fileList.length === 0 && jawTrackerList.length === 0) {
                toast.success("La commande a bien été créée");
            }
            setLoading(false);
            emptyData(true);
        } else if (response.status === 400) {
            toast.warning("Veuillez vérifier que tous les champs ont bien été complété");
            setLoading(false);
        } else if (response.status === 409) {
            toast.error("Cette commande existe déjà");
            setLoading(false);
        } else {
            toast.error("Une erreur est survenue lors de la création de la commande");
            setLoading(false);
        }
    };

    const uploadJawTracker = async (id: string, patientId: string) => {
        let occuredErrors = false;
        try {
            for (const jawTracketVideo of jawTrackerList) {
                const data = new FormData();
                data.append("file", jawTracketVideo);
                const resp = await JawTrackerAPI.uploadFiles(data, patientId, id);
                if (resp.status !== 200 && resp.status !== 201) {
                    occuredErrors = true;
                    toast.warning("La commande a été créée mais certains fichiers LIOMOVE ne se sont pas importés correctement.");
                }
            }
        } catch (error) {
            occuredErrors = true;
            console.error("Une erreur est survenue lors de l'upload des fichiers : ", error);
            toast.warning("La commande a été créée mais certains fichiers LIOMOVE ne se sont pas importés correctement.");
        } finally {
            if (!occuredErrors)
                toast.success("La commande a bien été créée");
            setLoading(false);
        }
    };

    const uploadFiles = async (id: string) => {
        let occuredErrors = false;

        try {
            const data = new FormData();
            fileList.forEach((file) => {
                const oldFile = file;
                const newFile = new File([oldFile], oldFile.name, { type: oldFile.type });
                data.append("files", newFile);
                data.append("description[]", btoa(file.name));
            })
            data.append("orderId", id);

            const resp = await OrderAPI.uploadFiles(data);
            if (resp.status !== 200 && resp.status !== 201 && resp.status !== 413) {
                occuredErrors = true;
                toast.warning("La commande a été créée mais certains fichiers ne se sont pas importés correctement.");
            } else if (resp.status === 413) {
                occuredErrors = true;
                toast.warning("La commande a été créée mais certains fichiers sont trops volumineux.");
            }
        } catch (error) {
            occuredErrors = true;
            console.error("Une erreur est survenue lors de l'upload des fichiers : ", error);
            toast.warning("La commande a été créée mais certains fichiers ne se sont pas importés correctement.");
        } finally {
            if (jawTrackerList.length === 0) {
                if (!occuredErrors)
                    toast.success("La commande a bien été créée");
                setLoading(false);
            }
        };
    };

    const emptyData = (all: boolean) => {
        if (all) {
            setOrder({
                patientId: "",
                patientName: "",
                patientFirstname: "",
                patientCivility: "",
                practicerId: Storage.getRoles().includes("COMPANY") ? "" : Storage.getPracticerId() ?? "",
                companyId: "",
                comment: "",
            });
            setFileList([]);
            setJawTrackerList([]);
        }
    };

    const handleSelectChange = async (event: any, name: string) => {
        if (event !== undefined) {
            if (name === "patientId") setOrder({ ...order, patientId: event.value });
            else if (name === "practicerId") setOrder({ ...order, practicerId: event.value });
            else if (name === "civility") setOrder({ ...order, patientCivility: event.value });
            else return
        }
    };

    const handleInputChange = (event: any) => {
        setOrder({ ...order, comment: event.target.value });
    };

    const handleFilesChange = async (event: any) => {
        const newFiles = event.target.files;
        const tmp = fileList;
        if (tmp === null) setFileList(newFiles);
        else setFileList([...tmp, ...newFiles]);
    };

    const removeLineFile = (index: number) => {
        const arr = fileList;
        if (index < 0 || index >= arr.length) toast.error("error");
        else {
            const newArray = [...arr.slice(0, index), ...arr.slice(index + 1)];
            setFileList(newArray);
        }
    };

    const stableSetOrder = useCallback((newOrder: any) => {
        setOrder(newOrder);
    }, []);

    const handleChangeValue = (value: { date: Moment }) => {
        setDueDate(value.date);
    }

    const handleJoyrideEnd = async (data: any) => {
        setStep(data.index)
        if (data.index === 1)
            setRadio("new");
        if (data.action === "close") {
            setRun(false);
        }
        if (data.status === "finished" || data.status === "skipped") {
            setPlayTutorial(false);
            const tutorial = await TutorialsAPI.getTutorials();
            if (tutorial.status !== 200) return;
            const myTutorial = tutorial.body;
            const currentPage = "/dash/order";
            const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
            if (currentTutorial !== undefined) {
                currentTutorial.visited = true;
            } else {
                myTutorial.push({ page: currentPage, visited: true });
            }
            const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
            if (response.status !== 200) return;
        }
    }

    const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setRun(true)
    };

    const endTutorial = async () => {
        setPlayTutorial(false);
        const tutorial = await TutorialsAPI.getTutorials();
        if (tutorial.status !== 200) return;
        const myTutorial = tutorial.body;
        const currentPage = "/dash/order";
        const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
        if (currentTutorial !== undefined) {
            currentTutorial.visited = true;
        } else {
            myTutorial.push({ page: currentPage, visited: true });
        }
        const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
        if (response.status !== 200) return;
    }

    const handleJawTrackerChange = async (event: any) => {
        console.log("JawTracker")
        const newFiles = event.target.files;
        const tmp = jawTrackerList;
        if (tmp === null) setJawTrackerList(newFiles);
        else setJawTrackerList([...tmp, ...newFiles]);
    };

    const removeJawTracker = (index: number) => {
        const arr = jawTrackerList;
        if (index < 0 || index >= arr.length) toast.error("error");
        else {
            const newArray = [...arr.slice(0, index), ...arr.slice(index + 1)];
            setJawTrackerList(newArray);
        }
    };

    return (
        <>
            <div className="hidden md:flex">
                <BottomTutorial
                    endTutorial={endTutorial}
                    handleClickStart={handleClickStart}
                    playTutorial={playTutorial}
                    step={step}
                />
                <Joyride
                    callback={handleJoyrideEnd}
                    continuous
                    run={run}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    steps={steps}
                    styles={{
                        options: {
                            zIndex: 99999999,
                        },
                        tooltip: {
                            padding: 20,
                        },
                        beacon: {
                            marginLeft: 5,
                        },
                    }}
                    locale={{
                        back: "Précédent",
                        close: "Fermer",
                        last: "Terminer",
                        next: "Suivant",
                        skip: "Passer",
                    }}
                />
            </div>
            <div className="mt-12 mb-8 flex flex-col gap-12 relative z-10">
                <Card>
                    <CardHeader variant="gradient" color="deep-purple" className="mb-4 p-4 flex z-50 relative">
                        <Typography variant="h6" color="white" className={`first-step ${playTutorial ? "pl-6" : "pl-0"}`}>
                            Nouvelle Commande
                        </Typography>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            {loading && <div className="absolute inset-0 bg-white bg-opacity-50 z-40 flex items-center justify-center rounded-xl">
                                <Spinner />
                            </div>}
                            <h4 className="text-capucine-700 font-bold font-sans">Informations client</h4>
                            <hr className="mt-2 border-royal-900" />
                            <div className="flex flex-col md:flex-row gap-4 mt-8">
                                <SelectPatient handleSelectChange={handleSelectChange} order={order} setOrder={stableSetOrder} radio={radio} setRadio={setRadio} loading={loading} />
                                <div className="w-full md:w-1/2 relative">
                                    <Textarea
                                        label="Commentaire"
                                        color="deep-purple"
                                        className="second-step fifth-step"
                                        value={order.comment}
                                        onChange={(event) => handleInputChange(event)}
                                        containerProps={{
                                            className: "h-full"
                                        }}
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                            <FileDropZone setFileList={setFileList} handleFilesChange={handleFilesChange} fileList={fileList} removeLineFile={removeLineFile} name="client" filesTypes="" />
                            <FileDropZone setFileList={setJawTrackerList} handleFilesChange={handleJawTrackerChange} fileList={jawTrackerList} removeLineFile={removeJawTracker} name="LIO MOVE" filesTypes="Vidéo" />
                            <div className="w-full -mt-6 md:mt-2 mb-2 seventh-step">
                                <Calendar loading={loading} dueDate={dueDate} onDueDateChange={handleChangeValue} />
                            </div>
                            <Button color="deep-purple" className="mt-4 w-full eighth-step" type="submit">
                                {loading
                                    ? <FontAwesomeIcon icon="spinner" spin />
                                    : "Créer la commande"
                                }
                            </Button>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
