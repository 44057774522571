import React, { useState } from "react";

import { Badge, Button, Typography } from "@material-tailwind/react";
import ModalGlobal from "./Modals/ModalGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import Pagination from "./Pagination";
import OrderAPI from "../services/API/clients/OrderAPI";

interface Props {
    header: any;
    data: {
        pagination: {
            currentPage: number,
            lastPage: number,
            totalItem: number,
            perPage: number
        },
        data: any[]
    };
    headerExtends: any;
    from: string;
    maj: any;
    notifications?: any[];
    handlePageChange: (page: number) => void;
}

const TableExtend: React.FC<Props> = ({ header, data, headerExtends, from, maj, notifications, handlePageChange }) => {
    const [expandedElement, setExpandedElement] = useState<[] | null>(null);
    const [open, setOpen] = React.useState(false);
    const [myObjectEdit, setMyObjectEdit] = React.useState<any>({});

    const handleSwitchToTnt = async (id: string) => {
        const response = await OrderAPI.trackingUrl(id);
        if (response.status === 200 || response.status === 201) {
            const url = response.body.url
            window.open(url, '_blank')
        }
    };

    const handleOpen = (_value: string) => {
        setOpen(!open);
        maj();
    };

    const handleOpenElement = (element: any) => {
        setMyObjectEdit(element);
        setOpen(!open);
    };

    const handleChooseColorText = (arg: string) => {
        switch (arg) {
            case "WAITING":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-cyan-100 rounded-full">Attente</div>;
            case "VALID":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "MODELING":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "LAB_CHIEF_VERIFICATION":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "LAB_CHIEF_VERIFIED":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "CLIENT_VERIFICATION":
                return (
                    <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-indigo-100 rounded-full">
                        En attente de vérification
                    </div>
                );
            case "CLIENT_VERIFIED":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Modélisation</div>;
            case "MODELING_DONE":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-orange-100 rounded-full">Production</div>;
            case "PRODUCTION":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-blue-100 rounded-full">Production</div>;
            case "PRODUCTION_DONE":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-blue-100 rounded-full">Production</div>;
            case "FINISHING":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-purple-100 rounded-full">Finition</div>;
            case "FINISHING_DONE":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-purple-100 rounded-full">Finition</div>;
            case "VERIFICATION":
                return (
                    <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-pink-100 rounded-full">Attente d'envoi</div>
                );
            case "VERIFIED":
                return (
                    <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-pink-100 rounded-full">Attente d'envoi </div>
                );
            case "SENT":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-amber-100 rounded-full">Envoyée</div>;
            case "INVALID":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-red-100 rounded-full">Invalide</div>;
            case "CANCELED":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-gray-100 rounded-full">Annulée</div>;
            case "WAITING_INSTRUCTION":
                return <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 py-1 bg-cyan-100 rounded-full">En cours</div>;
            default:
                return;
        }
    };

    const renderedColumn = (value: any, isTeethColumn: boolean, isId: boolean, element: { code: string }, index: number, key: number) => {
        if (isTeethColumn) return value.join(", ");
        else if (isId) return `${element.code}-${index}`;
        // else if (isId)
        //     return `00000${key}`
        else return value;
    };

    const ExpandedPart = (element: any, className: string, key: number) => {
        if (expandedElement === element)
            return (
                <tr className="border-b border-blue-gray-50">
                    <td colSpan={header.length} className={className}>
                        <table className="w-full table-auto overflow-x-auto">
                            <thead>
                                <tr>
                                    {headerExtends.map((column: any) => (
                                        <th key={column.id} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                            <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                                {column.id}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {element.products.map((product: any, index: number) => {
                                    return (
                                        <tr key={product.id}>
                                            {headerExtends.map((column: any, i: number) => {
                                                const columnKeys = column.col.split(".");
                                                const value = columnKeys.reduce((obj: any, key: any) => obj && obj[key], product);
                                                const isTeethColumn = column.col === "teeth";
                                                const isId = column.col === "code";
                                                return (
                                                    <td key={column.col + product.id} className={className}>
                                                        <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                                                            {renderedColumn(value, isTeethColumn, isId, element, index, key)}
                                                        </Typography>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
            );
    };

    const toNormal = (element: any, className: string, key: number) => {
        return (
            <>
                {header.map((column: any, i: number) => (
                    <td
                        key={column.id}
                        className={className}
                        onClick={() => setExpandedElement((prevExpandedElement: any) => (prevExpandedElement === element ? null : element))}
                    >
                        {overStep(column, element, i)}
                    </td>
                ))}
            </>
        );
    };

    const overStep = (column: any, element: any, i: number) => {
        return (
            <>
                {column.col === "step" ? (
                    handleChooseColorText(element[column.col])
                ) : column.col === "code" ? (
                    <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                        {notifications !== undefined && notifications.some((item) => (item.context.orderId === element.id && (
                            (from === "finished" && (item.action === "cancel" || item.action === "send")) ||
                            (from === "invalid" && (item.action === "updateInvalid" || item.action === "update" || item.action === "reportError" || item.action === "refuseWaiting")) ||
                            (from === "working" && (item.action === "reassignLaboratory" || item.action === "reassignEmployee" || item.action === "reassignTask" || item.action === "acceptWaiting" || item.action === "assignEmployee" || item.action === "askForClientVerification" || item.action === "acceptModelByClient" || item.action === "refuseModelByClient" || item.action === "finishModeling" || item.action === "finishProduction" || item.action === "finishFinishing" || item.action === "accept" || item.action === "refuse"))
                        ))) ? (
                            <Badge color="red" placement="top-start" withBorder >
                                {element[column.col]}
                            </Badge>
                        ) : (
                            element[column.col]
                        )}
                        
                    </Typography>
                ) : (
                    <Typography variant="small" color="blue-gray" className={i === 0 ? "font-bold" : ""}>
                        {element[column.col]}
                    </Typography>
                )}
            </>
        );
    };

    const toEdit = (element: any, className: string) => {
        return (
            <>
                {(element.step === "SENT" && element.travelVoucher &&
                    <td className={`${className} w-8`}>
                        <Button variant="outlined" color="blue" onClick={() => handleSwitchToTnt(element.id)} className="mx-1 text-xs py-2 px-4 whitespace-nowrap" disabled={!element.travelVoucher}>
                            suivi colis
                        </Button>
                    </td>
                )}
                {(element.step === "SENT" && !element.travelVoucher &&
                    <td className={`${className}`}>
                        
                    </td>
                )}
                <td className={`${className} w-8`} onClick={() => handleOpenElement(element)}>
                    <FontAwesomeIcon icon="pen-to-square" className="cursor-pointer" onClick={() => handleOpenElement(element)} />
                </td>
                <td className={`${className} w-8`}>
                    <a href={`/dash/chat/${element.id}`}>
                        {notifications !== undefined && notifications.some((item) => (item.context.orderId === element.id && item.action === "messageSent")) ? (
                            <Badge color="red" placement="top-end" withBorder >
                                <ChatBubbleLeftIcon className="h-5 w-5 text-capucine-700 cursor-pointer" />
                            </Badge>
                        ) : (
                            <ChatBubbleLeftIcon className="h-5 w-5 text-capucine-700 cursor-pointer" />
                        )}
                    </a>
                </td>
            </>
        );
    };

    return (
        <>
            <ModalGlobal open={open} setOpen={handleOpen} type={from} data={myObjectEdit} maj={maj} />
            <div className="table-container">
                <table className="w-full table-auto overflow-auto">
                    <thead>
                        <tr>
                            {header.map((column: any) => (
                                <th key={column.col} className={`border-b border-blue-gray-50 py-3 px-5 text-left`}>
                                    <Typography variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                        {column.id}
                                    </Typography>
                                </th>
                            ))}
                            {(data.data !== undefined && data.data.length > 0 && data.data[0].step === "SENT") ? (
                                <>
                                    <th className="border-b border-blue-gray-50 py-3 px-5 text-left"></th>
                                    <th className="border-b border-blue-gray-50 py-3 px-5 text-left"></th>
                                    <th className="border-b border-blue-gray-50 py-3 px-5 text-left"></th>
                                </>
                            ) : (
                                <>
                                    <th className="border-b border-blue-gray-50 py-3 px-5 text-left"></th>
                                    <th className="border-b border-blue-gray-50 py-3 px-5 text-left"></th>
                                </>
                            )}
                        </tr>
                        <tr></tr>
                    </thead>
                    <tbody>
                        {data.data !== undefined &&
                            data.data.map((element: any, key: number) => {
                                const className = `py-3 px-5 ${key === data.data.length - 1 ? "" : expandedElement === element ? "" : "border-b border-blue-gray-50"}`;

                                return (
                                    <React.Fragment key={element.id}>
                                        <tr className={`example-element-row ${expandedElement === element ? "example-expanded-row" : ""}`}>
                                            {toNormal(element, className, key)}
                                            {toEdit(element, className)}
                                        </tr>
                                        {ExpandedPart(element, className, key)}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </table>
            </div>

            <Pagination
                handlePageChange={handlePageChange}
                itemsPerPage={data.pagination.perPage}
                currentPage={data.pagination.currentPage}
                totalItems={data.pagination.totalItem}
                startIndex={data.pagination.perPage * (data.pagination.currentPage - 1)}
                endIndex={data.pagination.totalItem > (data.pagination.perPage * data.pagination.currentPage) ? 
                    data.pagination.perPage * data.pagination.currentPage - 1 : data.pagination.totalItem - 1
                }
            />
        </>
    );
};

export default TableExtend;
