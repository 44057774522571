import React, { useEffect, useState } from "react";
import CalendarDay from "./CalendarDay";
import { Typography } from "@material-tailwind/react";
import moment, { Moment } from "moment";
import OrderAPI from "../../../services/API/clients/OrderAPI";

interface CalendarProps {
    dueDate: Moment | null;
    onDueDateChange: (value: { date: Moment }) => void;
    loading: boolean
}

const Calendar: React.FC<CalendarProps> = ({ dueDate, onDueDateChange, loading }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [legend, setLegend] = useState<{ slotAvailabilityType: string; priceIncrease: number; dayToCompare: number }[]>([]);
    const [reservationData, setReservationData] = useState([]);

    const fetchLegend = async () => {
        const response = await OrderAPI.legend();
        if (response.status === 200)
            setLegend(response.body)
    }

    useEffect(() => {
        fetchLegend()
    }, [])

    const fetchData = async () => {
        const data = {
            year: moment.utc(currentDate).year(),
            month: moment.utc(currentDate).month() + 1,
        }
        const response = await OrderAPI.calendar(data);
        setReservationData(response.body)
    }


    useEffect(() => {
        fetchData()
    }, [currentDate])

    const handleDayClick = (date: Date | null, slotAvailability: string) => {
        if (slotAvailability === "UNAVAILABLE") return;
        if (date === null) return;
        onDueDateChange({ date: moment(date.toString()) });
    };

    const handleMonthChange = (increment: number) => {
        if (increment === -1)
            setCurrentDate(moment.utc(currentDate).subtract(1, "month").toDate());
        else setCurrentDate(moment.utc(currentDate).add(1, "month").toDate());
    };

    if (!reservationData) {
        return <div>Loading...</div>;
    }

    const generateEmptyDays = () => {
        const firstDayOfMonth = moment.utc(currentDate).startOf("month").day();
        const emptyDays = [];

        for (let i = 0; i < firstDayOfMonth - 1; i++) {
            emptyDays.push(
                <CalendarDay loading={loading} key={`empty-${i}`} date={null} color="EMPTY" onClick={() => { }} />
            );
        }

        return emptyDays;
    };

    return (
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2">
                <div className="flex justify-between items-center mb-2">
                    <button type="button" onClick={() => handleMonthChange(-1)} className="text-xs font-bold">Mois précédent</button>
                    <h2 className="tracking-wide">{`${moment.utc(currentDate).format("MMM YYYY")}`}</h2>
                    <button type="button" onClick={() => handleMonthChange(1)} className="text-xs font-bold">Mois suivant</button>
                </div>
                <div className="grid grid-cols-7 gap-2">
                    {reservationData && reservationData !== undefined && reservationData.length > 0 && generateEmptyDays().concat(
                        reservationData.map(({ slotAvailability, date }) => {
                            return (<CalendarDay loading={loading} key={date} date={date} color={slotAvailability} onClick={() => handleDayClick(date, slotAvailability)} />)
                        })
                    )}
                </div>
            </div>
            <div className="w-full md:w-1/2 pl-8 pt-8">
                <Typography color="gray" className="text-sm mt-1 font-bold mb-1">Légende</Typography>
                <div className="flex flex-row items-center mb-0.5">
                    <div className={`h-3 w-3 ${loading ? "bg-gray-500" : "bg-gray-200"} mr-2 rounded-full`}></div>
                    <Typography color="gray" className="text-xs">Indisponible</Typography>
                </div>
                {legend && legend.length > 0 && (
                    <div className="flex flex-row items-center mb-0.5">
                        <div className={`h-3 w-3 ${loading ? "bg-gray-500" : "bg-red-200"} mr-2 rounded-full`}></div>
                        <Typography color="gray" className="text-xs">{legend?.[1]?.priceIncrease}% de majoration</Typography>
                    </div>
                )}
                {legend && legend.length > 1 && (
                    <div className="flex flex-row items-center mb-0.5">
                        <div className={`h-3 w-3 ${loading ? "bg-gray-500" : "bg-orange-200"} mr-2 rounded-full`}></div>
                        <Typography color="gray" className="text-xs">{legend?.[2]?.priceIncrease}% de majoration</Typography>
                    </div>
                )}
                <div className="flex flex-row items-center">
                    <div className={`h-3 w-3 ${loading ? "bg-gray-500" : "bg-green-200"} mr-2 rounded-full`}></div>
                    <Typography color="gray" className="text-xs">Pas de majoration</Typography>
                </div>
                {dueDate && <Typography color="gray" className="text-xs mt-2"><span className="font-bold">Echéance de la commande :</span> {moment.utc(dueDate).format("DD.MM.YYYY")}</Typography>}
            </div>
        </div>
    );
};

export default Calendar;
