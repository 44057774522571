import React, { useEffect } from "react";
import { Card, CardHeader, Input, Spinner, Typography } from "@material-tailwind/react";
import "tw-elements";

import { HeaderExtends, Header } from "./Headers";

import Table from "../../components/TableExtend";
import Alert from "../../components/Alerts";
import OrderAPI from "../../services/API/clients/OrderAPI";
import NotificationAPI from "../../services/API/clients/NotificationAPI";

/** For Tutorial */
import Joyride from "react-joyride";
import { steps, facticeOrder } from "./Steps";
import TableExtendTutorial from "../../components/TableExtendTutorial";
import TutorialsAPI from "../../services/API/clients/TutorialsAPI";
import BottomTutorial from "../../components/BottomTutorial";

export default function Working() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [orders, setOrders] = React.useState<{
        pagination: {
            currentPage: number,
            perPage: number,
            totalItem: number,
            lastPage: number,
        },
        data: any[]
    }>({
        pagination: {
            currentPage: 1,
            perPage: 0,
            totalItem: 0,
            lastPage: 0,
        },
        data: []
    });
    const [error, setError] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [filteredOrders, setFilteredOrders] = React.useState<{
        pagination: {
            currentPage: number,
            perPage: number,
            totalItem: number,
            lastPage: number,
        },
        data: any[]
    }>(orders);
    const [notifications, setNotifications] = React.useState([]);
    /** For Tutorial */
    const [step, setStep] = React.useState(0);
    const [playTutorial, setPlayTutorial] = React.useState(false);
    const [run, setRun] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [myObjectEdit, setMyObjectEdit] = React.useState<any>(facticeOrder[0]);

    const [isLoaded, setIsLoaded] = React.useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const fetchData = async () => {
        const res = await NotificationAPI.getNotifs();
        if (res.status === 200) {
            setNotifications(res.body);
            const allIds = res.body.map((notif: any) => {
                if (
                    notif.action === "reassignLaboratory" ||
                    notif.action === "reassignEmployee" ||
                    notif.action === "reassignTask" ||
                    notif.action === "acceptWaiting" ||
                    notif.action === "assignEmployee" ||
                    notif.action === "askForClientVerification" ||
                    notif.action === "acceptModelByClient" ||
                    notif.action === "refuseModelByClient" ||
                    notif.action === "finishModeling" ||
                    notif.action === "finishProduction" ||
                    notif.action === "finishFinishing" ||
                    notif.action === "accept" ||
                    notif.action === "refuse"
                )
                    return notif.id;
                return undefined;
            });
            const uniqueIds = allIds.filter((value: string, index: number, self: any) => self.indexOf(value) === index);
            const removeUndefined = uniqueIds.filter((value: any) => value !== undefined);
            if (removeUndefined.length > 0) {
                await NotificationAPI.doneNotif({ ids: removeUndefined });
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            setIsLoaded(false);
            const resp = await OrderAPI.getOrders(
                "?step[]=VALID&step[]=MODELING&step[]=CLIENT_VERIFICATION&step[]=PRODUCTION&step[]=FINISHING&step[]=VERIFICATION&step[]=VERIFIED&step[]=WAITING_INSTRUCTION"
            );
            if (resp.status === 200) {
                setOrders(resp.body);
                setFilteredOrders(resp.body);
                setIsLoaded(true);
            } else {
                setIsLoaded(true);
                setError(true);
            }
        }
        fetchData();
    }, []);

    const handleMAJ = async () => {
        setIsLoaded(false);
        const response = await OrderAPI.getOrders(
            "?step[]=VALID&step[]=MODELING&step[]=CLIENT_VERIFICATION&step[]=PRODUCTION&step[]=FINISHING&step[]=VERIFICATION&step[]=VERIFIED&page=1"
        );
        if (response.status === 200) {
            setOrders(response.body);
            setFilteredOrders(response.body);
            setIsLoaded(true);
            setError(false);
        } else {
            setError(true);
            setIsLoaded(true);
        }
    };

    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await OrderAPI.getOrders(`?step[]=VALID&step[]=MODELING&step[]=CLIENT_VERIFICATION&step[]=PRODUCTION&step[]=FINISHING&step[]=VERIFICATION&step[]=VERIFIED${(searchQuery !== undefined && searchQuery !== "") ? `&fullText=${searchQuery}`: ""}&page=1`);
            if (response.status === 200) {
                setFilteredOrders(response.body);
                setIsLoaded(true);
                setError(false);
            } else {
                setError(true);
                setIsLoaded(true);
            }
        };
        const fetchTimeout = setTimeout(() => {
            fetchData();
        }, 200);
        return () => clearTimeout(fetchTimeout);
    }, [searchQuery]);

    const handlePageChange = async (page: number) => {
        setIsLoaded(false);
        if (searchQuery === "" || searchQuery === undefined) {
            const response = await OrderAPI.getOrders(`?step[]=VALID&step[]=MODELING&step[]=CLIENT_VERIFICATION&step[]=PRODUCTION&step[]=FINISHING&step[]=VERIFICATION&step[]=VERIFIED&page=${page}`);
            if (response.status === 200) {
                setFilteredOrders(response.body);
                setIsLoaded(true);
                setError(false);
            } else {
                setError(true);
                setIsLoaded(true);
            }
        } else {
            const response = await OrderAPI.getOrders(`?step[]=VALID&step[]=MODELING&step[]=CLIENT_VERIFICATION&step[]=PRODUCTION&step[]=FINISHING&step[]=VERIFICATION&step[]=VERIFIED&fullText=${searchQuery}&page=${page}`);
            if (response.status === 200) {
                setFilteredOrders(response.body);
                setIsLoaded(true);
                setError(false);
            } else {
                setError(true);
                setIsLoaded(true);
            }
        }
    };

    /** For Tutorial */

    const handleJoyrideEnd = async (data: any) => {
        setStep(data.index);
        if (data.index === 3) {
            setOpen(false);
        }
        if (data.index === 4) {
            setOpen(true);
        }
        if (data.index === 11) {
            setOpen(true);
        }
        if (data.index === 12) {
            setOpen(false);
        }
        if (data.action === "close") {
            setRun(false);
        }
        if (data.status === "finished" || data.status === "skipped") {
            setPlayTutorial(false);
            const tutorial = await TutorialsAPI.getTutorials();
            if (tutorial.status !== 200) return;
            const myTutorial = tutorial.body;
            const currentPage = "/dash/working";
            const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
            if (currentTutorial !== undefined) {
                currentTutorial.visited = true;
            } else {
                myTutorial.push({ page: currentPage, visited: true });
            }
            const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
            if (response.status !== 200) return;
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await TutorialsAPI.getTutorials();
            if (response.status !== 200) return;
            const tutorial = response.body;
            const orderPageVisited = tutorial.find((item: any) => item.page === "/dash/working")?.visited;
            if (orderPageVisited !== undefined) {
                if (orderPageVisited) {
                    setPlayTutorial(false);
                } else {
                    if (width > 768) setPlayTutorial(true);
                    else setPlayTutorial(false);
                }
            }
        };
        fetchData();
    }, []);

    const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setRun(true);
    };

    const endTutorial = async () => {
        setPlayTutorial(false);
        const tutorial = await TutorialsAPI.getTutorials();
        if (tutorial.status !== 200) return;
        const myTutorial = tutorial.body;
        const currentPage = "/dash/working";
        const currentTutorial = myTutorial.find((item: any) => item.page === currentPage);
        if (currentTutorial !== undefined) {
            currentTutorial.visited = true;
        } else {
            myTutorial.push({ page: currentPage, visited: true });
        }
        const response = await TutorialsAPI.seeTutorial({ pages: myTutorial });
        if (response.status !== 200) return;
    };

    return (
        <>
            <BottomTutorial endTutorial={endTutorial} handleClickStart={handleClickStart} playTutorial={playTutorial} step={step} />
            <Joyride
                callback={handleJoyrideEnd}
                continuous
                run={run}
                scrollToFirstStep
                showProgress
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        zIndex: 99999999,
                    },
                    tooltip: {
                        padding: 20,
                    },
                    beacon: {
                        marginLeft: 5,
                    },
                }}
                locale={{
                    back: "Précédent",
                    close: "Fermer",
                    last: "Terminer",
                    next: "Suivant",
                    skip: "Passer",
                }}
            />
            <div className="mt-12 mb-8 flex flex-col gap-12">
                <Card>
                    <CardHeader variant="gradient" color="deep-purple" className="mb-4 p-4 flex">
                        <Typography variant="h6" color="white" className={`first-step background-step ${playTutorial ? "pl-6" : "pl-0"}`}>
                            Commandes en cours
                        </Typography>
                    </CardHeader>
                    {!isLoaded ? (
                        <Spinner color="deep-purple" className="mx-auto mb-4 mt-2 w-6 h-6" />
                    ) : error ? (
                        <Alert message="Un problème est survenu lors du chargement des commandes en cours" />
                    ) : orders === undefined ? (
                        <Alert message="Chargement des commandes en cours..." />
                    ) : playTutorial ? (
                        <>
                            <div className="w-full p-4 second-step">
                                <Input
                                    type="text"
                                    label="Rechercher un patient ou un praticien"
                                    color="deep-purple"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>
                            <div className="w-full overflow-auto third-step">
                                <TableExtendTutorial
                                    header={Header}
                                    data={facticeOrder}
                                    from="working"
                                    maj={handleMAJ}
                                    headerExtends={HeaderExtends}
                                    notifications={notifications}
                                    open={open}
                                    setOpen={setOpen}
                                    myObjectEdit={myObjectEdit}
                                    setMyObjectEdit={setMyObjectEdit}
                                />
                            </div>
                        </>
                    ) : !playTutorial && orders.pagination.totalItem === 0 ? (
                        <Alert message="Aucune commande n'est en cours pour le moment" />
                    ) : (
                        !playTutorial && (
                            <>
                                <div className="w-full p-4">
                                    <Input
                                        type="text"
                                        label="Rechercher un patient ou un praticien"
                                        color="deep-purple"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <div className="w-full overflow-auto">
                                    <Table
                                        header={Header}
                                        data={filteredOrders}
                                        from="working"
                                        maj={handleMAJ}
                                        headerExtends={HeaderExtends}
                                        notifications={notifications}
                                        handlePageChange={handlePageChange}
                                    />
                                </div>
                            </>
                        )
                    )}
                </Card>
            </div>
        </>
    );
}
