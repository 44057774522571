import React, { useEffect } from "react";
import { Alert, Textarea } from "@material-tailwind/react";
import SelectPatient, { FileDropZone } from "../../SelectPatient";
import moment from "moment";

export default function PatientModalTutorial({ setOpen, data }: Readonly<{ setOpen: (open: string, arg0?: boolean) => void; data: any }>) {
    const [order, setOrder] = React.useState<any>({});
    
    const files = {
        uploaded: {
            id: 8241,
            name: "Client",
            description: "Fichiers téléversés par le client",
            createdAt: "2024-02-03T13:58:34.000Z",
            updatedAt: "2024-02-03T13:58:34.000Z",
            files: [
                {
                    id: 474,
                    public: true,
                    name: "Radio.jpg",
                    description: '{"description":"Y2FjaHVldGUuanBn"}',
                    type: "jpg",
                    size: 5895,
                    uploadedAt: "2024-02-03T13:58:35.000Z",
                    updatedAt: "2024-02-03T13:58:35.000Z",
                },
            ],
        },
        delivery: {
            id: 8248,
            name: "Rendu",
            description: "Fichiers rendus disponibles pour le client",
            createdAt: "2024-02-03T13:58:34.000Z",
            updatedAt: "2024-02-03T13:58:34.000Z",
            files: [],
        },
    };

    useEffect(() => {
        setOrder(data);
    }, [data]);
    
    const handleFilesChange = async (files: any) => {
        console.log()
    };

    const getMyFile = async (file: any) => {
        console.log()
    };

    return (
        <form className="modal-step">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 overflow-y-hidden">
                {order.statuses !== null && order.statuses !== undefined && order.statuses.find((item: { status: string }) => item.status === "INVALID") && (
                    <div className="messageError-step flex flex-col items-start gap-4 w-full rounded-lg bg-gray-50 p-4 mb-2">
                        <b className="text-capucine-700">Motif de refus : </b>
                        {order.statuses !== null &&
                            order.statuses !== undefined &&
                            order.statuses.find((item: { status: string }) => item.status === "INVALID").comment}
                    </div>
                )}
                <h4 className="text-capucine-700 font-bold font-sans">Informations de base</h4>
                <Alert className="my-2" color="deep-purple">
                    <b>Echéance : </b> {moment(order.deliveryTarget).format("DD.MM.YYYY")}
                </Alert>
                <hr className="mt-2 border-royal-900" />
                <div className="allBlock-step">
                    <div className="flex flex-col md:flex-row gap-4 mt-8">
                        <SelectPatient order={order} setOrder={setOrder} />
                        <div className="w-full md:w-1/2 relative">
                            <Textarea
                                label="Commentaire"
                                color="deep-purple"
                                value={order.comment}
                                className="block commentError-step"
                                containerProps={{
                                    className: "h-full",
                                }}
                            />
                        </div>
                    </div>
                    <FileDropZone handleFilesChange={handleFilesChange} files={files} getMyFile={getMyFile} loadingClient={false} loadingTracker={false} handleNewTracker={() => console.log()} />
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    type="submit"
                    className="modifOrder-step inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                >
                    Modifier la commande
                </button>
                <button
                    type="button"
                    className="cancelOrder-step inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                    Annuler la commande
                </button>
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen("editpatient")}
                >
                    Fermer
                </button>
            </div>
        </form>
    );
}
