import { Input } from "@material-tailwind/react";
import HandleErrors from "../../../components/HandleErrors";

export default function FirstPart({ handleInputChange, status, formData }: Readonly<{ handleInputChange: any; status: { message: string[] }; formData: any  }>) {
    const formInput = [
        {
            name: "name",
            type: "text",
            label: "Nom de l'entreprise",
            required: true,
        },
        {
            name: "siret",
            type: "text",
            label: "SIRET",
            required: true,
        },
        {
            name: "address",
            type: "text",
            label: "Adresse",
            required: true,
        },
        {
            name: "city",
            type: "text",
            label: "Ville",
            required: true,
        },
        {
            name: "postalCode",
            type: "text",
            label: "Code postal",
            min: 5,
            max: 5,
            required: true,
        },
        {
            name: "country",
            type: "text",
            label: "Pays",
            required: true,
        },
    ];

    const handleError = (name: string) => {
        const errorMessages: { [key: string]: string[] } = {
            name: ["name should not be empty", "name must be a string"],
            siret: ["siret must be a valid SIRET number."],
            address: ["address should not be empty", "address must be a string"],
            city: ["city should not be empty", "city must be a string"],
            postalCode: ["postalCode should not be empty", "postalCode must be a string"],
            country: ["country should not be empty", "country must be a string"],
        };

        return errorMessages[name].some((message) => status.message.includes(message));
    };

    const handlePattern = (name: string) => {
        if (name === "postalCode") return "[0-9]{5}";
        else return undefined;
    };

    return (
        <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 flex flex-row flex-wrap">
            {formInput.map((input: any) => (
                <>
                    {input.name === "name" && <h4 className="w-full text-royal-900 border-b border-b-capucine-700 pb-2">Entreprise</h4>}
                    <div className="relative w-full md:w-1/2 p-2" key={input.name}>
                        <Input
                            autoComplete="off"
                            id={input.name}
                            name={input.name}
                            type={input.type}
                            className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder={input.label}
                            onChange={handleInputChange}
                            value={formData[input.name]}
                            label={input.label}
                            variant="standard"
                            color="deep-purple"
                            required={input.required}
                            minLength={input.min}
                            maxLength={input.max}
                            pattern={handlePattern(input.name)}
                            error={handleError(input.name)}
                        />
                        {input.name === "name" && status.message.includes("name should not be empty") && (
                            <HandleErrors error="Le nom de l'entreprise ne peut pas être vide" color="red" />
                        )}
                        {input.name === "name" && status.message.includes("name must be a string") && (
                            <HandleErrors error="Le nom de l'entreprise doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "siret" && status.message.includes("siret must be a valid SIRET number.") && (
                            <HandleErrors error="Le SIRET doit être un numéro SIRET valide" color="red" />
                        )}
                        {input.name === "phone" && status.message.includes("phone must be a valid French phone number.") && (
                            <HandleErrors error="Le numéro de téléphone doit être un numéro de téléphone français valide" color="red" />
                        )}
                        {input.name === "mobilePhone" && status.message.includes("mobilePhone must be a valid French phone number.") && (
                            <HandleErrors error="Le numéro de téléphone portable doit être un numéro de téléphone portable français valide" color="red" />
                        )}
                        {input.name === "address" && status.message.includes("address should not be empty") && (
                            <HandleErrors error="L'adresse ne peut pas être vide" color="red" />
                        )}
                        {input.name === "address" && status.message.includes("address must be a string") && (
                            <HandleErrors error="L'adresse doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "city" && status.message.includes("city should not be empty") && (
                            <HandleErrors error="La ville ne peut pas être vide" color="red" />
                        )}
                        {input.name === "city" && status.message.includes("city must be a string") && (
                            <HandleErrors error="La ville doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "postalCode" && status.message.includes("postalCode should not be empty") && (
                            <HandleErrors error="Le code postal ne peut pas être vide" color="red" />
                        )}
                        {input.name === "postalCode" && status.message.includes("postalCode must be a string") && (
                            <HandleErrors error="Le code postal est composé de 5 chiffres" color="red" />
                        )}
                        {input.name === "country" && status.message.includes("country should not be empty") && (
                            <HandleErrors error="Le pays ne peut pas être vide" color="red" />
                        )}
                        {input.name === "country" && status.message.includes("country must be a string") && (
                            <HandleErrors error="Le pays doit être une chaîne de caractères" color="red" />
                        )}
                        {input.name === "phone" && !status.message.includes("phone must be a valid phone number") && (
                            <HandleErrors error="Format : +33ABBCCDDEE" color="gray" />
                        )}
                        {input.name === "mobilePhone" && !status.message.includes("mobilePhone must be a valid phone number") && (
                            <HandleErrors error="Format : +33ABBCCDDEE" color="gray" />
                        )}
                        {input.name === "phone" && status.message.includes("phone must be a valid phone number") && (
                            <HandleErrors error="Format : +33ABBCCDDEE" color="red" />
                        )}
                        {input.name === "mobilePhone" && status.message.includes("mobilePhone must be a valid phone number") && (
                            <HandleErrors error="Format : +33ABBCCDDEE" color="red" />
                        )}
                    </div>
                </>
            ))}
        </div>
    );
}
