import APIClient from "../APIClients";

export default class OrderAPI {
    public static async newOrder(data: {}) {
        return APIClient.client.post("/order", data);
    }

    public static async getOrder(id: string) {
        return APIClient.client.get(`/order?id=${id}`);
    }

    public static async getOrders(params: string = "") {
        return APIClient.client.get(`/order/find${params}`);
    }

    public static async uploadFiles(data: {}) {
        return APIClient.client.postPicture("/files/upload/customer", data);
    }
    
    public static async updateOrder(data: {}) {
        return APIClient.client.patch("/order/update-invalid", data)
    }

    public static async cancel(data: {}) {
        return APIClient.client.post("/order/cancel", data);
    }

    public static async finishVerification(data: {}) {
        return APIClient.client.post("/order/verify-model-by-client", data);
    }

    public static async getFiles(id: string) {
        return APIClient.client.get(`/files/list/customer?orderId=${id}`)
    }

    public static async infoFiles(id: string) {
        return APIClient.client.get(`/files/info/${id}`)
    }

    public static async downloadFile(id: string, asName: string) {
        return APIClient.client.getAndDownload(`/files/download/${id}`, asName)
    }

    public static async trackingUrl(id: string) {
        return APIClient.client.getRedirect(`/order/order-tracking-url/${id}`)
    }
    
    public static async calendar(body: {}) {
        return APIClient.client.post("/calendar", body)
    }

    public static async legend() {
        return APIClient.client.get("/calendar/legend")
    }
}
