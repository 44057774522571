import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionBody, AccordionHeader, Input, Typography } from "@material-tailwind/react";
import { useState } from "react";

export default function FourthStep({ formData }: Readonly<{ formData: any }>) {
    const [open, setOpen] = useState(1);
    const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

    return (
        <div className="py-8 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7 flex flex-col flex-wrap">
            <h4 className="w-full text-royal-900 border-b border-b-capucine-700 pb-2 mb-4">Confirmer l'inscription</h4>
            <Typography variant="paragraph">Est-ce que les informations saisies vous conviennent ? </Typography>
            <Accordion open={open === 1}>
                <AccordionHeader className="border-b-royal-900 text-royal-900" onClick={() => handleOpen(1)}>
                    <div className="flex w-full items-center justify-between">
                        <span>Informations Générales</span>
                        <FontAwesomeIcon icon={open === 1 ? "chevron-up" : "chevron-down"} className="text-royal-900" />
                    </div>
                </AccordionHeader>
                <AccordionBody className="bg-gray-50 rounded-b-xl">
                    <div className="text-base leading-6 text-gray-700 sm:text-lg sm:leading-7 flex flex-row flex-wrap">
                        <div className="relative w-full md:w-1/2 p-2">
                            <Input value={formData.name} color="deep-purple" disabled />
                        </div>
                        <div className="relative w-full md:w-1/2 p-2">
                            <Input value={formData.siret} color="deep-purple" disabled />
                        </div>
                        <div className="relative w-full md:w-1/2 p-2">
                            <Input value={formData.address} color="deep-purple" disabled />
                        </div>
                        <div className="relative w-full md:w-1/2 p-2">
                            <Input value={formData.city} color="deep-purple" disabled />
                        </div>
                        <div className="relative w-full md:w-1/2 p-2">
                            <Input value={formData.postalCode} color="deep-purple" disabled />
                        </div>
                        <div className="relative w-full md:w-1/2 p-2">
                            <Input value={formData.country} color="deep-purple" disabled />
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
                <AccordionHeader className="border-b-royal-900 text-royal-900" onClick={() => handleOpen(2)}>
                    <div className="flex w-full items-center justify-between">
                        <span>Praticiens</span>
                        <FontAwesomeIcon icon={open === 2 ? "chevron-up" : "chevron-down"} className="text-royal-900" />
                    </div>
                </AccordionHeader>
                <AccordionBody className="bg-gray-50 rounded-b-xl">
                    <div className="text-sm text-gray-700 flex flex-row flex-wrap p-2 gap-2">
                        {formData.praticers.map((praticer: any, index: number) => (
                            <div className="flex flex-col gap-4 relative bg-blue-gray-50/50 p-4 rounded-xl" key={praticer.lastname + praticer.lastname}>
                                <div className="flex flex-col gap-4">
                                    <div className="w-full flex flex-col lg:flex-row gap-4">
                                        <div className="relative w-full lg:w-1/3 h-10">
                                            <Input type={"text"} value={praticer.firstname} color="deep-purple" disabled />
                                        </div>
                                        <div className="relative w-full lg:w-1/3 h-10">
                                            <Input type={"text"} value={praticer.name} color="deep-purple" disabled />
                                        </div>
                                        <div className="relative w-full lg:w-1/3 h-10">
                                            <Input type={"text"} value={praticer.profession} color="deep-purple" disabled />
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col lg:flex-row gap-4">
                                        <div className="relative w-full lg:w-1/3 h-10">
                                            <Input type={"text"} value={praticer.mail} color="deep-purple" disabled />
                                        </div>
                                        <div className="relative w-full lg:w-1/3 h-10">
                                            <Input type={"text"} value={praticer.fixePhone} color="deep-purple" disabled />
                                        </div>
                                        <div className="relative w-full lg:w-1/3 h-10">
                                            <Input type={"text"} value={praticer.phone} color="deep-purple" disabled />
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col lg:flex-row gap-4">
                                        <div className="relative w-full lg:w-1/2 h-10">
                                            <Input type={"text"} value={praticer.civility} color="deep-purple" disabled />
                                        </div>
                                        <div className="relative w-full lg:w-1/2 h-10">
                                            <Input type={"password"} value={praticer.password} color="deep-purple" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </AccordionBody>
            </Accordion>
            {formData.mainContactEmail !== "" && (
                <Accordion open={open === 3}>
                    <AccordionHeader className="border-b-royal-900 text-royal-900" onClick={() => handleOpen(3)}>
                        <div className="flex w-full items-center justify-between">
                            <span>Compte Entreprise</span>
                            <FontAwesomeIcon icon={open === 3 ? "chevron-up" : "chevron-down"} className="text-royal-900" />
                        </div>
                    </AccordionHeader>
                    <AccordionBody className="bg-gray-50 rounded-b-xl">
                        <div className="text-base leading-6 text-gray-700 sm:text-lg sm:leading-7 flex flex-row flex-wrap">
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.email} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input type="password" value={formData.password} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mobilePhone} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.phone} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mainContactEmail} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mainContactFirstname} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mainContactLastname} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mainContactProfession} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mainContactMobilePhone} color="deep-purple" disabled />
                            </div>
                            <div className="relative w-full md:w-1/2 p-2">
                                <Input value={formData.mainContactPhone} color="deep-purple" disabled />
                            </div>
                        </div>
                    </AccordionBody>
                </Accordion>
            )}
        </div>
    );
}
