import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Spinner, Typography } from "@material-tailwind/react";

export default function SelectPatient({ order, setOrder }: Readonly<{ order: any; setOrder: any }>) {
    return (
        <div className="w-full md:w-1/2 flex flex-col gap-2 justify-center">
            <Input label="Praticien" type="text" color="deep-purple" name="practicerFullName" id="practicerFullName" value={order.practicerFullName} disabled />
            <Input label="Patient" type="text" color="deep-purple" name="patientFullName" id="patientFullName" value={order.patientFullName} onChange={(e) => setOrder({...order, patientFullName: e.target.value })} />
        </div>
    );
}

export function FileDropZone({
    handleFilesChange,
    files,
    getMyFile,
    handleNewTracker,
    loadingTracker,
    loadingClient
}: Readonly<{
    handleFilesChange: (event: any) => Promise<void>;
    files: any;
    getMyFile: (id: string, name: string) => Promise<void>;
    handleNewTracker: (event: any) => void;
    loadingTracker: boolean;
    loadingClient: boolean;
}>) {

    const downloadAllOfThem = (key: string) => {
        files[key].files.forEach((fichier: { id: string; name: string }) => {
            getMyFile(fichier.id, fichier.name);
        });
    }

    return (
        <div className="flex flex-col md:flex-row gap-3">
            <div className="flex flex-col w-full md:w-1/2 my-2 fileError-step">
                <div className="flex items-center justify-center w-full my-2 fileError-step">
                    <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            {loadingClient 
                                ? <Spinner color="deep-purple" />
                                : <>
                                    <svg className="w-7 h-7 mb-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        ></path>
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        Fichiers <span className="font-semibold">clients</span>
                                    </p>
                                </> 
                            }
                        </div>
                        <input id="dropzone-file" type="file" className="hidden" onChange={handleFilesChange} multiple />
                    </label>
                </div>
                <div className="flex items-center justify-center w-full my-2 fileError-step">
                    <label
                        htmlFor="dropzone-file-2"
                        className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        {loadingTracker 
                                ? <Spinner color="deep-purple" />
                                : <>
                                    <svg className="w-7 h-7 mb-1 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        ></path>
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        Fichiers <span className="font-semibold">LIO MOVE</span>
                                    </p>
                                </> 
                            }
                        </div>
                        <input id="dropzone-file-2" type="file" className="hidden" onChange={handleNewTracker} multiple />
                    </label>
                </div>
            </div>
            <div className="w-full md:w-1/2 h-auto rounded-md px-4 pt-2 mt-2 filesError-step" style={{ backgroundColor: "#eceff1" }}>
                {files !== undefined && Object.keys(files).length > 0 ? (
                    <ul className="mb-1">
                        {Object.keys(files).map((key) => (
                            <li key={files[key].id} className="mb-1">
                                <Typography variant="small" className="font-normal text-blue-gray-700 text-base">
                                    • {files[key].name} {files[key].files.length > 0 && <IconButton size="sm" className="text-xs ml-1" color="lime" onClick={() => downloadAllOfThem(key)}><FontAwesomeIcon icon="download"  className="text-white" /></IconButton>}
                                </Typography>
                                <ul className="mt-0.5 ml-6">
                                    {files[key].files.length === 0 ? (
                                        <li>
                                            <Typography variant="small" className="font-normal text-blue-gray-700 text-sm">
                                                Aucun fichier
                                            </Typography>
                                        </li>
                                    ) : (
                                        files[key].files.map((fichier: { id: string; name: string }) => (
                                            <li key={fichier.id}>
                                                <Typography variant="small" className="font-normal text-blue-gray-700 text-sm flex items-center">
                                                    <FontAwesomeIcon
                                                        icon="download"
                                                        onClick={() => getMyFile(fichier.id, fichier.name)}
                                                        className="bg-royal-900 hover:bg-royal-800 p-2 text-xs text-white rounded-lg mr-2 mb-0.5 cursor-pointer"
                                                    />
                                                    {fichier.name}
                                                </Typography>
                                            </li>
                                        ))
                                    )}
                                </ul>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Input color="blue-gray" className="font-normal text-left pt-2 pl-2" value="Aucun fichiers" disabled />
                )}
            </div>
        </div>
    );
}
