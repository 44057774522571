import React from "react";
import AuthAPI from "../../services/API/clients/AuthAPI";
import { Button, Spinner, Step, Stepper } from "@material-tailwind/react";
import { toast } from "react-toastify";
import FirstPart from "./RegisterParts/FirstPart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecondPart from "./RegisterParts/SecondPart";
import ThirdStep from "./RegisterParts/ThirdPart";
import FourthStep from "./RegisterParts/FourthPart";

type Practicer = {
    firstname: string;
    lastname: string;
    civility: string;
    profession: string;
    email: string;
    password: string;
    phone?: string;
    mobilePhone: string;
};

type MainContact = {
    email: string;
    firstname: string;
    lastname: string;
    profession: string;
    phone?: string;
    mobilePhone: string;
};

type Account = {
    email: string;
    password: string;
    phone?: string;
    mobilePhone: string;
};

type Datas = {
    name: string;
    siret: string;
    address: string;
    city: string;
    postalCode: string;
    country: string;
    mainContact?: MainContact;
    account?: Account;
    practicers: Practicer[];
};

export default function RegisterStepper() {
    const [whichAAccount, setWhichAAccount] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState({
        name: "",
        siret: "",
        phone: "",
        mobilePhone: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        // End first part
        praticers: [
            {
                lastname: "",
                name: "",
                firstname: "",
                profession: "",
                mail: "",
                phone: "",
                fixePhone: "",
                civility: "Monsieur",
                password: "",
            },
        ],
        // End second part
        email: "",
        password: "",
        mainContactEmail: "",
        mainContactFirstname: "",
        mainContactLastname: "",
        mainContactProfession: "",
        mainContactPhone: "",
        mainContactMobilePhone: "",
        // End third part
    });
    const [loading, setLoading] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [status, setStatus] = React.useState<{ message: string[] }>({
        message: [],
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLastStep, setIsLastStep] = React.useState(false);
    const [isFirstStep, setIsFirstStep] = React.useState(false);

    const handlePrev = () => {
        if (activeStep === 0) return;
        if ((activeStep === 3 && formData.praticers.length === 1) || formData.praticers.length === 0) {
            setActiveStep(1);
            return;
        }
        setActiveStep(activeStep - 1);
    };

    const handleInputChange = (event: { target: { name: any; value: any } }) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const datas: Datas = {
            name: formData.name,
            siret: formData.siret,
            address: formData.address,
            city: formData.city,
            postalCode: formData.postalCode,
            country: formData.country,
            practicers: formData.praticers.map((praticer) => {
                return {
                    firstname: praticer.firstname,
                    lastname: praticer.name,
                    civility: praticer.civility === "MADAME" ? "WOMAN" : praticer.civility === "Monsieur" ? "MAN" : "OTHER",
                    profession: praticer.profession,
                    email: praticer.mail,
                    password: praticer.password,
                    mobilePhone: "+33" + praticer.phone.replace(/^0/, ""),
                    ...(praticer.fixePhone !== "" && { phone: "+33" + praticer.fixePhone.replace(/^0/, "") })
                };
            })
        };
        if (formData.mainContactEmail !== "") {
            datas.mainContact = {
                email: formData.mainContactEmail,
                firstname: formData.mainContactFirstname,
                lastname: formData.mainContactLastname,
                profession: formData.mainContactProfession,
                mobilePhone: "+33" + formData.mainContactMobilePhone.replace(/^0/, ""),
            };
            if (formData.mainContactPhone !== "") 
                datas.mainContact.phone = "+33" + formData.mainContactPhone.replace(/^0/, "");
        }
        if (formData.email !== "") {
            datas.account = {
                email: formData.email,
                password: formData.password,
                mobilePhone: "+33" + formData.mobilePhone.replace(/^0/, ""),
            };
            if (formData.phone !== "") 
                datas.account.phone = "+33" + formData.phone.replace(/^0/, "");
        }
        const response = await AuthAPI.register(datas);
        if (response.status === 200 || response.status === 201) {
            toast.success("Votre compte a bien été créé"); 
            setIsLoading(false);
            window.location.href = "/";
        } else if (response.status === 400) {
            toast.error("Le formulaire n'a pas été correctement rempli");
            setIsLoading(false);
        } else if (response.status === 409) {
            toast.error("Un compte existe déjà avec cette adresse email");
            setIsLoading(false);
        } else {
            toast.error("Une erreur est survenue");
            setIsLoading(false);
        }
    };

    const handleSubmitMyPart = async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setLoading(true);
        if (activeStep === 0) {
            setStatus({ message: [] });
            const phoneNumberRegex = /^\d{10}$/;
            let statuses: { message: string[] } = { message: [] };
            if (formData.phone && !phoneNumberRegex.test(formData.phone)) {
                statuses = { message: [...statuses.message, "phone must be a valid French phone number."] };
            }
            if (formData.mobilePhone && !phoneNumberRegex.test(formData.mobilePhone)) {
                statuses = { message: [...statuses.message, "mobilePhone must be a valid French phone number."] };
            }
            if (formData.postalCode && !formData.postalCode.match(/^[0-9]{5}$/)) {
                statuses = { message: [...statuses.message, "postalCode must be a valid French postal code."] };
            }
            setStatus(statuses);
            if (statuses.message.length > 0) {
                setLoading(false);
                return;
            } else {
                setStatus({ message: [] });
                setActiveStep(1);
                setLoading(false);
            }
        } else if (activeStep === 1) {
            setStatus({ message: [] });
            let statuses: { message: string[] } = { message: [] };
            formData.praticers.forEach((element, index) => {
                const phoneNumberRegex = /^\d{10}$/;
                if (element.phone && !phoneNumberRegex.test(element.phone)) {
                    statuses = { message: [...statuses.message, `praticers.${index}.phone`] };
                }
                if (element.fixePhone && !phoneNumberRegex.test(element.fixePhone)) {
                    statuses = { message: [...statuses.message, `praticers.${index}.fixePhone`] };
                }
                if (element.mail && !element.mail.match(/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                    statuses = { message: [...statuses.message, `praticers.${index}.mail`] };
                }
            });
            setStatus(statuses);
            if (statuses.message.length > 0) {
                setLoading(false);
                return;
            } else {
                setStatus({ message: [] });
                if (formData.praticers.length === 1 || formData.praticers.length === 0) setActiveStep(3);
                else setActiveStep(2);
                setLoading(false);
            }
        } else if (activeStep === 2) {
            setStatus({ message: [] });
            let statuses: { message: string[] } = { message: [] };
            const phoneNumberRegex = /^\d{10}$/;
            if (formData.mainContactPhone && !phoneNumberRegex.test(formData.mainContactPhone)) {
                statuses = { message: [...statuses.message, `mainContactPhone must be a valid French phone number.`] };
            }
            if (formData.mainContactMobilePhone && !phoneNumberRegex.test(formData.mainContactMobilePhone)) {
                statuses = { message: [...statuses.message, `mainContactMobilePhone must be a valid French phone number.`] };
            }
            if (formData.email && !formData.email.match(/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                statuses = { message: [...statuses.message, `email must be an email`] };
            }
            if (formData.mainContactEmail && !formData.mainContactEmail.match(/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                statuses = { message: [...statuses.message, `mainContactEmail must be an email`] };
            }
            setStatus(statuses);
            if (statuses.message.length > 0) {
                setLoading(false);
                return;
            } else {
                setStatus({ message: [] });
                setActiveStep(3);
                setLoading(false);
            }
        } else if (activeStep === 3) {
            handleSubmit();
        } else {
            alert("Error");
        }
        setLoading(false);
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden h-5/6">
            <div className="relative py-3 mx-auto w-full md:w-2/3 h-5/6">
                <div className="absolute inset-0 bg-gradient-to-r from-capucine-700 to-capucine-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl h-5/6"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-autovh-5/6">
                    <h1 className="text-2xl text-royal-900 font-semibold mb-2">Espace Collaborateur</h1>
                    <div className="w-full py-4 px-8">
                        <Stepper
                            color="deep-purple"
                            lineClassName="bg-royal-900/50"
                            activeLineClassName="bg-royal-900"
                            activeStep={activeStep}
                            isLastStep={(value) => setIsLastStep(value)}
                            isFirstStep={(value) => setIsFirstStep(value)}
                        >
                            <Step activeClassName="ring-royal-900/20 bg-royal-900" completedClassName="bg-royal-900" color="deep-purple">
                                <FontAwesomeIcon icon="house-medical" className="text-white" />
                            </Step>
                            <Step activeClassName="ring-royal-900/20 bg-royal-900" completedClassName="bg-royal-900" color="deep-purple">
                                <FontAwesomeIcon icon="user-doctor" className="text-white" />
                            </Step>
                            <Step activeClassName="ring-royal-900/20 bg-royal-900" completedClassName="bg-royal-900" color="deep-purple">
                                <FontAwesomeIcon icon="house-medical-circle-exclamation" className="text-white" />
                            </Step>
                            <Step activeClassName="ring-royal-900/20 bg-royal-900" completedClassName="bg-royal-900" color="deep-purple">
                                <FontAwesomeIcon icon="check" className="text-white" />
                            </Step>
                        </Stepper>
                        <form onSubmit={handleSubmitMyPart}>
                            {activeStep === 0 && <FirstPart status={status} handleInputChange={handleInputChange} formData={formData} />}
                            {activeStep === 1 && <SecondPart status={status} setFormData={setFormData} formData={formData} />}
                            {activeStep === 2 && (
                                <ThirdStep
                                    status={status}
                                    setFormData={setFormData}
                                    formData={formData}
                                    setActiveStep={setActiveStep}
                                    setWhichAAccount={setWhichAAccount}
                                    whichAAccount={whichAAccount}
                                />
                            )}
                            {activeStep === 3 && <FourthStep formData={formData} />}
                            <div className="flex justify-between">
                                <Button type="button" onClick={handlePrev} disabled={isFirstStep} color="lime" className="text-white">
                                    Précédent
                                </Button>
                                {activeStep !== 3 ? (
                                    <Button type="submit" disabled={isLastStep} color="deep-purple">
                                        {loading ? <Spinner color="deep-purple" /> : "Suivant"}
                                    </Button>
                                ) : (
                                    <Button type="submit" color="deep-purple">
                                        {isLoading ? <Spinner color="deep-purple" /> : "Terminer"}
                                    </Button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
